<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{formFields.id ? `Edit ${operationTitle}` : `Add ${operationTitle}`}}
                    </template>
                    <template v-slot:toolbar>
                        <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                                  size="sm"
                                  variant="primary"
                                  :disabled="global.pendingRequests > 0"
                        >
                            <i v-show="global.pendingRequests > 0"
                               class="fa fa-spin fa-spinner"></i>
                            <i class="fa fa-save fa-sm"></i>
                            Save
                        </b-button>
                        <b-button variant="danger" size="sm" class="mt-3"
                                  @click="handleOperationClose()">
                            <i class="fa fa-arrow-left fa-sm"></i> Cancel
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="events-operation">
                                <b-container fluid>
                                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                                        <b-row>
                                            <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                                                <b-row>
                                                    <b-col sm="6">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('title')"
                                                            label="Session *"
                                                            label-for="title"
                                                        >
                                                            <b-form-input
                                                                :state="((formErrors.has('title') ? false : null))"
                                                                @focus="$event.target.select()"
                                                                id="title"
                                                                type="text"
                                                                v-model="formFields.title"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col sm="6">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('lead_id')"
                                                            label="Select Lead *"
                                                            label-for="lead_id"
                                                        >
                                                            <treeselect
                                                                :class="[{'invalid is-invalid': (formErrors.has('lead_id'))}]"
                                                                :disabled="!!formFields.id"
                                                                :multiple="false"
                                                                :options="dropdowns.leads"
                                                                id="Lead"
                                                                label="lead_id"
                                                                v-model="formFields.lead_id"
                                                            />
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                                <b-row>
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('event_type')"
                                                            label="Event Type *"
                                                            label-for="event_type"
                                                        >
                                                            <treeselect
                                                                :class="[{'invalid is-invalid': (formErrors.has('event_type'))}]"
                                                                :options=" dropdowns.eventType"
                                                                v-model="formFields.event_type"
                                                            />
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('event_from_date')"
                                                            label="Event From Date *"
                                                            label-for="event_from_date"
                                                        >
                                                            <b-datepicker
                                                                :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                                                :state="((formErrors.has('event_from_date') ? false : null))"
                                                                id="event_from_date"
                                                                type="text"
                                                                v-model="formFields.event_from_date"
                                                            />
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('event_to_date')"
                                                            label="Event To Date *"
                                                            label-for="event_to_date"
                                                        >
                                                            <b-datepicker
                                                                :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                                                :state="((formErrors.has('event_to_date') ? false : null))"
                                                                id="event_to_date"
                                                                type="text"
                                                                v-model="formFields.event_to_date"
                                                            />
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <b-row>
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('no_of_guest')"
                                                            label="No Of Guest"
                                                            label-for="no_of_guest"
                                                        >
                                                            <b-form-input
                                                                :state="((formErrors.has('no_of_guest') ? false : null))"
                                                                @focus="$event.target.select()"
                                                                id="no_of_guest"
                                                                type="text"
                                                                v-model="formFields.no_of_guest"
                                                                v-numericOnly.keyup
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('client_budget')"
                                                            label="Client Budget"
                                                            label-for="client_budget"
                                                        >
                                                            <b-form-input
                                                                :state="((formErrors.has('client_budget') ? false : null))"
                                                                @focus="$event.target.select()"
                                                                id="client_budget"
                                                                type="text"
                                                                v-model="formFields.client_budget"
                                                                v-numericOnly.keyup
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('event_status')"
                                                            label="Event Status *"
                                                            label-for="event_status"
                                                        >
                                                            <b-form-select
                                                                :options="event_statues"
                                                                :state="((formErrors.has('status') ? false : null))"
                                                                v-model="formFields.event_status"
                                                            >
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <b-row v-show="formFields.status == 'Lost'">
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('drop_analytics')"
                                                            label="Drop Analytics"
                                                            label-for="status"
                                                        >
                                                            <b-form-select
                                                                :options="dropdowns.dropAnalytics"
                                                                :state="((formErrors.has('drop_analytics') ? false : null))"
                                                                v-model="formFields.drop_analytics"
                                                            >
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <b-row>
                                                    <b-col sm="12">
                                                        <b-form-group
                                                            :invalid-feedback="formErrors.first('description')"
                                                            label="Description"
                                                            label-for="description"
                                                        >
                                                            <b-form-textarea
                                                                :state="((formErrors.has('description') ? false : null))"
                                                                @focus="$event.target.select()"
                                                                id="description"
                                                                type="text"
                                                                v-model="formFields.description"
                                                            ></b-form-textarea>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <hr/>
                                                <b-row class="operation-footer">
                                                    <b-col sm="12">
                                                        <b-button
                                                            :disabled="global.pendingRequests > 0"
                                                            size="sm"
                                                            type="submit"
                                                            variant="primary"
                                                        >
                                                            <i class="fa fa-spin fa-spinner"
                                                               v-show="global.pendingRequests > 0"></i>
                                                            <i class="fa fa-save fa-sm"></i>
                                                            Save
                                                        </b-button>
                                                        <b-button @click="handleOperationClose()" class="ml-3"
                                                                  size="sm"
                                                                  variant="danger">
                                                            <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </form><!--/form-->
                                </b-container><!--/b-container-->
                            </div><!--/.events-operation-->
                        </v-card>
                    </template>
                </KTCard>
            </div>
        </div>
    </div>
</template>

<script>
    import KTCard from "@/view/content/Card.vue";
    import moduleMixin from "./module.mixin"
    import {mapGetters, mapState} from "vuex";

    export default {
        mixins: [moduleMixin],
        components: {
            KTCard,
        },
        mounted() {
            this.getEventType()
            this.getLeads()

            if (this.$route.params && this.$route.params.id) {
                this.handleEditOperation(this.$route.params.id)
            }
        },
        computed: {
            ...mapState([
                'global',
            ]),
            ...mapGetters({getActiveProperty: 'getActiveProperty'}),
        },
    }
</script>
